
import Vue, { PropType } from "vue";

import http from "@/http";

import { date } from "@/filters";

import { Owner, Building, Attachment } from "@/interfaces";

import MyOverview from "@/components/owner/MyOverview.vue";
import MyContact from "@/components/owner/MyContact.vue";
import MyTable from "@/components/notice/MyTable.vue";
import MyPreview from "@/components/MyPreview.vue";

import {
  mdiPlus,
  mdiDomain,
  mdiDelete,
  mdiAccountCircle,
  mdiImage
} from "@mdi/js";

import FileDownload from "js-file-download";

export interface DataType {
  attachment?: File;
  src?: string;
}

export default Vue.extend({
  filters: {
    date
  },
  components: {
    MyOverview,
    MyContact,
    MyTable,
    MyPreview
  },
  props: {
    owner: {
      type: Object as PropType<Owner>,
      required: true
    }
  },
  data(): DataType {
    return {
      src: "",
      attachment: undefined
    };
  },
  computed: {
    placeholder() {
      return "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2248px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2248px%22%20fill%3D%22%23cccccc%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3Cpath%20d%3D%22M12%2012c2.21%200%204-1.79%204-4s-1.79-4-4-4-4%201.79-4%204%201.79%204%204%204zm0%202c-2.67%200-8%201.34-8%204v2h16v-2c0-2.66-5.33-4-8-4z%22%2F%3E%3C%2Fsvg%3E";
    },
    icon() {
      return {
        mdiPlus,
        mdiDomain,
        mdiDelete,
        mdiAccountCircle,
        mdiImage
      };
    },
    uniqBuildings() {
      const buildings: Building[] = this.owner.buildings.reduce((a, v) => {
        if (!a.some(e => e.id === v.id)) {
          a.push(v);
        }
        return a;
      }, [] as Building[]);

      return buildings;
    }
  },
  watch: {
    async attachment() {
      await this.fetch();
    }
  },
  async mounted() {
    await this.fetch();
  },
  methods: {
    async fetch() {
      if (this.owner.attachment == null) {
        return;
      }

      const { attachment } = this.owner;

      const { data } = await http.get(`attachments/${attachment.id}`, {
        responseType: "blob"
      });

      this.src = URL.createObjectURL(data);
    },
    async remove() {
      if (this.owner.attachment == null) {
        return;
      }

      const { id } = this.owner.attachment;

      const url = `attachments/${id}`;

      await http.delete(url, {});

      this.owner.attachment = undefined;
    },
    async change() {
      if (this.attachment == null) {
        return;
      }

      const url = `owners/${this.owner.id}/attachments`;

      const formData = new FormData();

      formData.append("file", this.attachment);

      const { data } = await http.post<Attachment>(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      this.owner.attachment = data;

      await this.fetch();
    },
    async click() {
      if (!confirm("本当に登録解除しますか?")) {
        return;
      }
      const url = `/owners/${this.owner.id}`;

      await http.delete(url);

      this.owner.user = undefined;
    },
    async download(attachment: Attachment) {
      const { data } = await http.get(`attachments/${attachment.id}`, {
        responseType: "blob"
      });

      FileDownload(data, `${attachment.id}.pdf`);
    },
    async login() {
      if (!this.owner.user) {
        return;
      }

      const { data } = await http.get(`users/${this.owner.user.id}`);

      window.location.href = data.login_link;
    }
  }
});
