
import Vue, { PropType } from "vue";

import { Contact } from "@/interfaces";

export default Vue.extend({
  props: {
    contact: {
      type: Object as PropType<Contact>,
      required: true
    }
  }
});
